@import "variables";
@import "mixins/breakpoints";

.table {
  --border-color: $black;
  --text-color: $black;
  --border-styles: 1px solid $black;

  display: flex;
  align-items: center;
  justify-content: center;

  .table__scroll-container {
    @media (max-width: 1480px) {
      overflow-x: scroll;
    }
  }

  table {
    min-width: 1200px;
    border-collapse: collapse;
    border: var(--border-styles);
    color: var(--text-color);
  }

  tr {
    border: var(--border-styles);
    border-color: var(--border-color);
  }

  td,
  th {
    width: auto;
    max-width: 448px;
    min-width: 260px;
    border: var(--border-styles);
    border-color: var(--border-color);
  }

  caption {
    padding: 10px;
    caption-side: bottom;
    color: currentColor;
  }
}
